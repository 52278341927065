<template>
  <b-container fluid class="pt-5 pb-2 fontStandard">
    <b-row>
      <b-col cols="12" class="text-center">
        <small>
          <pre class="text-white">
JEAN Julien
EMAIL Contact : contact@julienjean.ovh
Application NaskotFolio v2.0b - Tous droits réservés {{ year }}</pre>
        </small>
        <b-link class="text-warning" @click="clear">Supprimer mes cookies sur ce site web</b-link>&nbsp;|&nbsp;
        <b-link class="text-warning" v-b-modal.mLTheTermsOfUse>Mentions légales</b-link>&nbsp;|&nbsp;
        <b-link class="text-warning" :to="{ path: '/sitemap' }">Plan du site</b-link>&nbsp;|&nbsp;
        <b-link class="text-warning">
          <b-img-lazy width="100px" :src="naskotlogo"></b-img-lazy>Réalisation Julien JEAN (Naskot)
        </b-link>

        <b-modal
          id="mLTheTermsOfUse"
          title="Mentions légales"
          ok-only
          centered
          header-bg-variant="dark"
          body-bg-variant="dark"
          footer-bg-variant="dark"
          ok-variant="light"
          header-close-variant="light"
          header-text-variant="light"
          header-border-variant="dark"
          hide-footer
          scrollable
          size="lg"
        >
          <TermsOfUse />
        </b-modal>
      </b-col>
    </b-row>

    <Perf />
  </b-container>
</template>

<script>
export default {
  components: {
    TermsOfUse: () => import("@/components/TermsOfUse"),
    Perf: () => import("@/components/Perf"),
  },
  methods: {
    clear() {
      localStorage.setItem("jwt", "");
      localStorage.clear();
      document.location.reload(true);
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    naskotlogo() {
      if (this.$tools.canUseWebP()) {
        return require("@/assets/webp/naskot_create_b.webp");
      } else {
        return require("@/assets/img/naskot_create_b.png");
      }
    },
  },
};
</script>
